
import Loadable from '@/components/shared/Loadable.vue';
import { withDefault } from '@/components/shared/utils';
import ClinicalDocumentBuilderView from '@/components/visit/clinical-document/ClinicalDocumentBuilderView.vue';
import { ClinicalDocumentType, DrawerType, MutateClinicalDocumentItem } from '@/models';
import { appealLetterTabs, ClinicalDocumentTypeDisplay, DocumentBuilderTab } from '@/models/clinical-document/ClinicalDocument.model';
import { deleteClinicalDocumentItem } from '@/shared/mutations';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { FaxNumber, Mutation, Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'AppealLetterController',
    components: {
        ClinicalDocumentBuilderView,
        Loadable,
    },
    provide: {
        showAddToClinicalSummaryButton: false,
        showDeleteFromClinicalSummaryButton: false,
        enableLabSelection: false,
        documentType: ClinicalDocumentType.APPEAL,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
        documentBuilderSelectedTab: { type: Number as PropType<DocumentBuilderTab>, required: false },
    },
    data: () => ({
        visitStore: useVisitStore(),
        visitDrawerStore: useVisitDrawerStore(),
        DocumentBuilderTab,
        appealLetterTabs,
        ClinicalDocumentType,
    }),
    computed: {
        isLoading(): boolean {
            const key = `${this.visitStore.visit?.id}_${this.visitStore.selectedDenialId}`;
            return !this.visitStore.appealLetterSummaries[key]?.id;
        },
        initialSelectedTab(): DocumentBuilderTab {
            return this.documentBuilderSelectedTab ?? DocumentBuilderTab.PAYER_NOTE;
        },
        currentAppealLetter() {
            const key = `${this.visit.id}_${this.visitStore.selectedDenialId}`;
            return this.visitStore.appealLetterSummaries[key] || null;
        },
    },
    async created() {
        await this.visitStore.getAppealLetterSummary();
    },
    methods: {
        withDefault,
        async deleteAppealLetterItem(item: MutateClinicalDocumentItem) {
            const clinicalDocument = this.currentAppealLetter;
            if (!clinicalDocument) return;
            await this.$apollo.mutate<Mutation>({
                mutation: deleteClinicalDocumentItem,
                variables: {
                    input: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: +clinicalDocument?.id,
                        ...item,
                    },
                },
            });
            this.$toast.success('Document deleted from Appeal Letter');
        },
        async completeAppealLetter(faxNumber: FaxNumber): Promise<void> {
            const clinicalDocument = this.currentAppealLetter;
            if (!clinicalDocument) return;
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.CLINICAL_DOCUMENT_CONFIRMATION,
                params: {
                    faxNumber: faxNumber,
                    payerName: withDefault(this.visit.primaryPayer?.name),
                    visitId: this.visit.id,
                    clinicalDocumentId: clinicalDocument?.id,
                    documentType: ClinicalDocumentType.APPEAL,
                },
                drawerProps: { dark: true },
                breadcrumbs: [
                    {
                        drawer: DrawerType.APPEAL_LETTER,
                        params: this.visitDrawerStore.params,
                        drawerProps: { dark: true },
                    },
                ],
            });
        },
        async deleteDocument() {
            this.visitStore.deleteDocument(ClinicalDocumentType.APPEAL);
            this.visitDrawerStore.closeDrawer();
            this.$toast.success(`Your ${ClinicalDocumentTypeDisplay[ClinicalDocumentType.APPEAL]} has been deleted!`);
        },
    },
});
