
import VerticalField from '@/components/shared/VerticalField.vue';
import { useFeatureStore } from '@/stores/FeatureStore';
import Vue from 'vue';

interface File {
    id: number;
    name: string;
}

export default Vue.extend({
    name: 'FileUploadField',
    components: {
        VerticalField,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        featureFlag: {
            type: String,
            required: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        featureStore: useFeatureStore(),
        files: [] as File[],
    }),
    computed: {
        isFeatureEnabled(): boolean {
            return this.featureFlag ? this.featureStore.isEnabled(this.featureFlag) : true;
        },
    },
    methods: {
        async uploadFiles(event: Event) {
            const target = event.target as HTMLInputElement;
            const files = target.files as FileList;

            if (files.length === 0) return;
            for (var i = 0; i < files.length; i++) {
                const file = files[i];
                this.files.push({ id: i, name: file.name });
            }
        },
        async deleteFile(id: number) {
            console.debug('Delete file id: ' + id);
        },
    },
});
