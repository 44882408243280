/**
 * Generates a unique string key by Base64 encoding each part and concatenating with ':'.
 * @param keyParts - An array of key values.
 * @returns A unique string key.
 */
export const generateCompositeKey = (keyParts: string[]): string => {
    return keyParts.map((part) => Buffer.from(part).toString('base64')).join(':');
};

/**
 * Parses the unique string key by splitting on ':' and Base64 decoding each part.
 * @param key - The unique string key.
 * @returns An array of key values.
 */
export const parseCompositeKey = (key: string): string[] => {
    return key.split(':').map((part) => Buffer.from(part, 'base64').toString('utf8'));
};

// Composite key interface
export interface CompositeKey {
    fields: string[]; // e.g., ['division', 'code']
    getter: (entity: any) => string[]; // Function to extract key parts from an entity
}
