
import DatePicker from '@/components/shared/DatePicker.vue';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import MultiSelectFilter from '@/components/shared/MultiSelectFilter.vue';
import { coerceToId, formatFacilityLocation, formatPersonName } from '@/components/shared/utils';
import { updateUserPreferences } from '@/shared/mutations';
import { getWorklistFilters } from '@/shared/queries';
import { useWorklistFiltersStore } from '@/stores/WorklistFiltersStore';
import { generateCompositeKey } from '@/utils/composity-key.util';
import {
    AdmitStatus,
    Facility,
    HospitalService,
    Location,
    Mutation,
    Payer,
    PayerCategory,
    Query,
    User,
    VisitFiltersInput,
} from 'generated/graphql/graphql';
import Vue from 'vue';
interface DivisionObject {
    division: string;
    code: string;
    name: string;
    inScope?: boolean;
}
export default Vue.extend({
    name: 'WorklistFilters',
    components: {
        DatePicker,
        MultiSelectFilter,
        FeatureToggle,
    },
    data: () => ({
        worklistFiltersStore: useWorklistFiltersStore(),
        admitStatusItems: null as AdmitStatus[] | null,
        predictedAdmitStatusItems: null as AdmitStatus[] | null,
        facilityItems: null as Facility[] | null,
        locationItems: null as Location[] | null,
        serviceItems: null as HospitalService[] | null,
        payerItems: null as Payer[] | null,
        payerCategoryItems: null as PayerCategory[] | null,
        umNurseOwnerItems: null as User[] | null,
        divisionItems: null as Facility[] | null,
    }),
    async created() {
        await this.loadItems();
        this.worklistFiltersStore.$subscribe(() => {
            if (this.worklistFiltersStore.autoSave) {
                this.saveUserPreferences(this.worklistFiltersStore.worklistFilterObject);
            } else {
                this.saveUserPreferences(null);
            }
        });
    },
    methods: {
        formatPersonName,
        formatFacilityLocation,
        coerceToId,
        divisionCodeEncode(divisionObject: DivisionObject): DivisionObject {
            if (!divisionObject.division || !divisionObject.code) {
                console.log(divisionObject);
                return divisionObject;
            }
            return {
                ...divisionObject,
                code: generateCompositeKey([divisionObject.division, divisionObject.code]),
            };
        },
        async loadItems(): Promise<void> {
            const response = await this.$apollo.query<Query>({
                query: getWorklistFilters,
            });
            this.admitStatusItems = response.data.admitStatuses;
            this.predictedAdmitStatusItems = response.data.predictedAdmitStatuses;
            this.facilityItems = response.data.facilities.map(this.divisionCodeEncode) as Facility[];
            this.locationItems = response.data.locations.map(this.divisionCodeEncode) as Location[];
            this.serviceItems = response.data.hospitalServices.map(this.divisionCodeEncode) as HospitalService[];
            this.payerItems = response.data.payers.map(this.divisionCodeEncode) as Payer[];
            this.payerCategoryItems = response.data.payerCategories.map(this.divisionCodeEncode);
            this.umNurseOwnerItems = response.data.usersByRole;
            this.divisionItems = response.data.facilities;
        },
        saveUserPreferences(filters: VisitFiltersInput | null): void {
            this.$apollo.mutate<Mutation>({
                mutation: updateUserPreferences,
                variables: {
                    input: {
                        worklistFilters: filters,
                    },
                },
            });
        },
    },
});
