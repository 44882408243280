
import { Role } from '@/auth/role.enum';
import DatePicker from '@/components/shared/DatePicker.vue';
import { EventBus, Events } from '@/components/shared/event-bus';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import ReviewFeedbackCard from '@/components/sidebar/ReviewFeedbackCard.vue';
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import { ReviewOutcome, ReviewType } from '@/models';
import { completeFirstLevelReview, completeSecondLevelReview, setNextReviewDate } from '@/shared/mutations';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import { Mutation } from 'generated/graphql/graphql';
import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
    name: 'CompleteReviewDialog',
    components: {
        SideBarButton,
        ReviewFeedbackCard,
        DatePicker,
        FeatureToggle,
    },
    props: {
        expanded: Boolean,
    },
    data: () => ({
        reviewId: undefined as string | undefined,
        isDialogOpen: false,
        isComplete: false,
        pendingMutation: false,
        userStore: useUserStore(),
        notes: '',
        admitStatuses: ['Inpatient', 'Observation', 'Emergency', 'Discharge'],
        recommendedAdmitStatus: null,
        visitStore: useVisitStore(),
        placeholderText: 'Recommended Patient Status*',
        featureStore: useFeatureStore(),
        nextReviewDate: null as string | null,
        ReviewType,
        ReviewOutcome,
    }),
    computed: {
        isUmNurse(): boolean {
            return this.userStore.currentRole === Role.UM_NURSE;
        },
        isMandatoryFieldPopulated(): boolean {
            if (this.userStore.currentRole === Role.PHYSICIAN_ADVISOR) {
                return !this.recommendedAdmitStatus;
            }
            return false;
        },
        showFirstLevelReview(): boolean {
            return !this.isComplete && this.isUmNurse;
        },
        showSecondLevelReview(): boolean {
            return !this.isComplete && this.userStore.currentRole === Role.PHYSICIAN_ADVISOR;
        },
        showReviewFeedback(): boolean {
            return this.isComplete;
        },
        isValidNextReviewDate(): boolean {
            return this.nextReviewDate === null || moment().isBefore(moment(this.nextReviewDate, 'YYYY-MM-DD'), 'day');
        },
        hasPendingSlr(): boolean {
            return !!this.visitStore.visit?.hasPendingSLR;
        },
    },
    watch: {
        isDialogOpen() {
            if (this.isDialogOpen) {
                this.reviewId = undefined;
                this.isComplete = false;
                this.notes = '';
            }
        },
    },
    methods: {
        clearPlaceholderText(): void {
            this.placeholderText = '';
        },
        async setNextReviewDate() {
            const reviewDateString = moment(this.nextReviewDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
            const toastMessage = this.nextReviewDate ? `Review Date Set to ${reviewDateString}` : 'Review Date Cleared';
            await this.$apollo.mutate<Mutation>({
                mutation: setNextReviewDate,
                variables: {
                    input: {
                        visitId: +this.$route.params.id,
                        nextReviewDate: this.nextReviewDate,
                    },
                },
            });
            EventBus.$emit(Events.NEXT_REVIEW_DATE_SET);
            this.$toast.success(toastMessage, { queueable: true, timeout: 1500 });
        },
        async completeFirstLevelReview() {
            this.pendingMutation = true;
            const response = await this.$apollo.mutate<Mutation>({
                mutation: completeFirstLevelReview,
                variables: {
                    visitId: +this.$route.params.id,
                    notes: this.notes,
                    visitSnapshotId: +(await this.visitStore.visitSnapshotId),
                },
                fetchPolicy: 'no-cache',
            });
            this.reviewId = response.data?.completeFirstLevelReview.id;
            this.markComplete();
            this.$toast.success('Review Successfully Completed', { queueable: true, timeout: 1500 });
            this.setNextReviewDate();
        },
        async completeSecondLevelReview() {
            this.pendingMutation = true;
            const response = await this.$apollo.mutate<Mutation>({
                mutation: completeSecondLevelReview,
                variables: {
                    visitId: +this.$route.params.id,
                    recommendedAdmitStatus: this.recommendedAdmitStatus,
                    notes: this.notes,
                    visitSnapshotId: +(await this.visitStore.visitSnapshotId),
                },
                fetchPolicy: 'no-cache',
            });
            this.reviewId = response.data?.completeSecondLevelReview.id;
            this.markComplete();
        },
        markComplete(): void {
            this.isComplete = true;
            this.pendingMutation = false;
        },
        closeDialog(): void {
            this.isDialogOpen = false;
            this.nextReviewDate = null;
        },
    },
});
