import App from '@/App.vue';
import { Config } from '@/config';
import vuetify from '@/plugins/vuetify';
import router, { pinia } from '@/router';
import SessionManager from '@/services/SessionManager';
import '@/styles/global.scss';
import '@/styles/typography.scss';
import { createProvider } from '@/vue-apollo';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { PiniaVuePlugin } from 'pinia';
import Vue, { markRaw } from 'vue';
import { Fragment } from 'vue-frag';
import infiniteScroll from 'vue-infinite-scroll';
import setupOpentelemetry from './opentelemetry';
import { bootstrapVisitStore, useVisitStore } from './stores/VisitStore';

Vue.config.productionTip = false;
Vue.config.errorHandler = function (e) {
    console.error(e);
    Vue.prototype.$toast.error('Uh oh! Looks like we are having trouble on our end. Try again later');
};

function bootstrap(config: Config) {
    const VISIT_REGEX = /\/visit\/(\d+)/;

    const oktaAuth = new OktaAuth({
        clientId: config.oidc.clientId,
        issuer: config.oidc.issuer,
        redirectUri: config.oidc.redirectUri,
        postLogoutRedirectUri: config.oidc.postLogoutRedirectUri,
        scopes: config.oidc.scopes,
        pkce: config.oidc.pkce,
    });

    const authMiddleware = setContext(() =>
        oktaAuth.tokenManager.getTokens().then((tokens) => {
            return {
                headers: {
                    Authorization: `Bearer ${tokens?.accessToken?.accessToken}`,
                },
            };
        })
    );

    const divisionMiddleware = setContext((_, context) => ({
        ...context,
        headers: {
            ...context.headers,
            'visit-id': Number.parseInt(VISIT_REGEX.exec(document.URL)?.[1] ?? ''),
        },
    }));

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, extensions }) => {
                console.log(`[GraphQL error]: ${message}`);
                switch (extensions?.code) {
                    case 'UNAUTHENTICATED':
                        oktaAuth.signOut();
                        return;
                }
                switch (extensions?.exception?.name) {
                    case 'PublicException':
                        Vue.prototype.$toast.error(extensions.exception.message, { timeout: -1 });
                        break;
                    case 'ForbiddenException':
                    case 'AuthenticationError':
                        // Lack of access is likely not to be something that can be "moved past", leave the toast open indefinitely
                        Vue.prototype.$toast.error("I'm sorry, but you don't seem to have access to do that.", { timeout: -1 });
                        break;
                    default:
                        Vue.prototype.$toast.error('Something went wrong.');
                        break;
                }
            });
        }
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }
    });

    Vue.use(OktaVue, {
        oktaAuth,
        onAuthRequired: () => {
            if (router.currentRoute.path !== '/login') {
                router.push('/login');
            }
        },
    });

    const apolloProvider = createProvider({
        httpEndpoint: config.graphql.url,
        link: ApolloLink.from([authMiddleware, divisionMiddleware, new RetryLink(), errorLink]),
        wsEndpoint: undefined,
    });
    //Initialize Opentelemetry
    if (process.env.NODE_ENV !== 'development') {
        setupOpentelemetry(config.graphql.url);
    }

    // Initialize SessionManager
    const sessionManager = new SessionManager(apolloProvider.defaultClient);
    Vue.prototype.$sessionManager = sessionManager; // Attach to Vue prototype
    // Make the apollo client available to non-visual components
    Vue.prototype.$apolloClient = markRaw(apolloProvider.defaultClient);
    Vue.use(PiniaVuePlugin);

    pinia.use(({ store }) => {
        store.apolloClient = markRaw(apolloProvider.defaultClient);
    });
    bootstrapVisitStore();
    Vue.use(infiniteScroll);
    Vue.component('Fragment', Fragment);

    router.beforeEach((to, from, next) => {
        if (from?.params?.id && from?.params?.id === to?.params?.id) {
            const visitStore = useVisitStore();
            visitStore.resetClinicalSummary();
        }
        next();
    });

    new Vue({
        vuetify,
        apolloProvider,
        pinia,
        router,
        render: (h) => h(App),
    }).$mount('#app');
}

// Load the environment config file before starting the app
fetch(process.env.BASE_URL + 'config.json')
    .then((response) => response.json())
    .then((configJson) => {
        const config = new Config(configJson);
        Vue.prototype.$config = config;
        bootstrap(config);
    });
