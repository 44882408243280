
import AdditiveSelector from '@/components/shared/AdditiveSelector.vue';
import {
    formatDate,
    formatPersonName,
    formatPhoneNumber,
    formatTime,
    phoneNumberValidator,
    uniqueCurriedValidator,
} from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import DeleteSavedClinicalSummaryDialog from '@/components/visit/clinical-summary/DeleteSavedClinicalSummaryDialog.vue';
import { ClinicalDocumentType } from '@/models';
import { ClinicalSummaryHistoryTab } from '@/models/clinical-document/ClinicalDocument.model';
import { assignFaxNumber, deleteClinicalDocument, saveClinicalDocument, sendClinicalDocument } from '@/shared/mutations';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import Vue, { PropType } from 'vue';
import { ClinicalDocument, FaxNumber, Mutation } from '../../../../generated/graphql/graphql';

export default Vue.extend({
    name: 'ClinicalSummarySavedViewCard',
    components: { AdditiveSelector, VerticalField, DeleteSavedClinicalSummaryDialog },
    props: {
        clinicalSummary: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
    },
    data: () => ({
        readyToSend: false,
        faxNumbers: [] as FaxNumber[],
        selectedFaxNumber: {} as FaxNumber | undefined | null,
        visitStore: useVisitStore(),
        visitDrawerStore: useVisitDrawerStore(),
    }),
    computed: {
        faxNumbersDisplay(): { value: FaxNumber; text: string }[] {
            return this.faxNumbers.map((fn) => ({ value: fn, text: fn.faxNumber }));
        },
    },
    async created() {
        this.readyToSend = this.clinicalSummary.isReadyToSend;
        this.getFaxNumbers();
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        formatPhoneNumber,
        phoneNumberValidator,
        uniqueCurriedValidator,
        async addFaxNumber(faxNumberString: string) {
            const mutation = this.$apollo.mutate<Mutation>({
                mutation: assignFaxNumber,
                variables: {
                    input: {
                        division: this.visitStore.visit?.division,
                        faxNumbers: [faxNumberString],
                        payerCodes: [this.visitStore.visit?.primaryPayer?.code],
                        type: ClinicalDocumentType.CLINICAL_SUMMARY,
                    },
                },
            });
            this.visitStore.handlePendingDocumentMutation(mutation);
            const faxNumber = (await mutation).data?.assignFaxNumber?.at(0);
            if (faxNumber === undefined) throw new Error('Unable to add fax number');
            this.faxNumbers.push(faxNumber);
            this.selectedFaxNumber = this.faxNumbers.at(-1);
        },
        getFaxNumbers(): void {
            this.faxNumbers = this.visitStore.visit?.primaryPayer?.faxNumbers ?? [];
            this.selectedFaxNumber = this.clinicalSummary.faxNumberId
                ? this.visitStore.getFaxFromId(this.clinicalSummary.faxNumberId)
                : this.faxNumbers[0];
        },
        async deleteSavedClinicalDocument() {
            if (!this.visitStore.visit) {
                console.error('No visit in store');
                return;
            }
            await this.$apollo.mutate<Mutation>({
                mutation: deleteClinicalDocument,
                variables: {
                    input: {
                        visitId: +this.visitStore.visit.id,
                        clinicalDocumentId: +this.clinicalSummary.id,
                    },
                },
            });
            this.goToClinicalSummaryList(ClinicalSummaryHistoryTab.SAVED_TAB);
            this.$toast.success('Clinical Summary Deleted', {
                classes: 'toast-width-auto',
            });
        },
        async sendClinicalReview() {
            try {
                if (!this.visitStore.visit) {
                    console.error('No visit in store');
                    return;
                }
                await this.$apollo.mutate<Mutation>({
                    mutation: sendClinicalDocument,
                    variables: {
                        input: {
                            visitId: +this.visitStore.visit.id,
                            clinicalDocumentId: +this.clinicalSummary.id,
                            faxNumberId: Number(this.selectedFaxNumber?.id),
                            hash: this.clinicalSummary.hash,
                            type: ClinicalDocumentType.CLINICAL_SUMMARY,
                        },
                    },
                });
                if (this.visitStore.clinicalSummary?.id === this.clinicalSummary.id) {
                    this.visitStore.clinicalSummary = undefined;
                }
                this.goToClinicalSummaryList(ClinicalSummaryHistoryTab.SENT_TAB);
                this.$toast.success('Your clinical summary has been sent!');
            } catch (e) {
                console.error(e);
            }
        },
        async updateReadyToSend() {
            if (!this.visitStore.visit) {
                console.error('No visit in store');
                return;
            }
            await this.$apollo.mutate<Mutation>({
                mutation: saveClinicalDocument,
                variables: {
                    input: {
                        visitId: +this.visitStore.visit.id,
                        clinicalDocumentId: +this.clinicalSummary.id,
                        isReadyToSend: this.readyToSend,
                    },
                },
            });
        },
        async updateFaxNumber() {
            if (!this.visitStore.visit) {
                console.error('No visit in store');
                return;
            }
            await this.$apollo.mutate<Mutation>({
                mutation: saveClinicalDocument,
                variables: {
                    input: {
                        visitId: +this.visitStore.visit.id,
                        clinicalDocumentId: +this.clinicalSummary.id,
                        isReadyToSend: this.readyToSend,
                        faxNumberId: Number(this.selectedFaxNumber?.id),
                    },
                },
            });
        },
        goToClinicalSummaryList(tabIndex: ClinicalSummaryHistoryTab) {
            this.$router.push({ name: 'clinicalsummarylist', params: { tabIndex: tabIndex.toString() } });
        },
    },
});
